<template>
  <el-header>

    <div class="title">
      <img :src="logo_url" alt=""/>
      <h3>Home</h3>
    </div>

    <el-alert
      class="confirmation-alert"
      v-if="reservationCreatedFromBooking.show"
      :title="`Reservation Confirmation #${reservationCreatedFromBooking.confirmation_no} created`"
      type="success"
      show-icon>
    </el-alert>

    <div class="name" @click="show = !show" v-on:mouseleave="show = false">
      <div class="user-name">
        <user-icon class="user-icon" />
        <span>{{ `${first_name} ${last_name}` }}</span>
        <chevron-down-icon v-if="!show" class="caret-icon" />
        <chevron-up-icon v-else class="caret-icon" />
      </div>

      <div class="logout" @click="logout" v-if="show">
        <log-out-icon class="custom-class" />
        <span>Logout</span>
      </div>
    </div>

      <el-dropdown trigger="click" class="user-icon-mobile">
      <span class="el-dropdown-link">
        <user-icon class="user-icon" style="padding-top: 3px" />
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item disabled>{{ `${first_name} ${last_name}` }}</el-dropdown-item>
          <el-dropdown-item divided icon="el-icon-arrow-left" @click.native="logout">
            Logout
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

  </el-header>
</template>

<script>
import {mapState} from "vuex";
import {ChevronDownIcon, ChevronUpIcon, LogOutIcon, UserIcon} from "vue-feather-icons";

export default {
  name: 'HkHeader',
  components: {
    UserIcon,
    LogOutIcon,
    ChevronDownIcon,
    ChevronUpIcon
  },
  mounted () {
    this.activate()
  },
  data() {
    return {
      logo_url: hkConfigEnvs.VUE_APP_LOGO,
      googleSignInParams: {
        client_id: hkConfigEnvs.VUE_APP_GOOGLE_CLIENT_ID,
        hosted_domain: "hotelkeyapp.com"
      },
      reservationCreatedFromBooking: {
        confirmation_no: null,
        show: false
      },
      show: false
    }
  },
  computed: {
    ...mapState({
      user: state => state?.current?.user,
      hk_info: state => state.current?.hk_info,
      google_login: state => state.current?.google_login
    }),
    first_name(){
      return this.user.first_name;
    },
    last_name(){
      return this.user.last_name;
    }
  },
  methods: {
    logout() {
      this.$emit('logUserOut')
    },
    activate() {
      if (!this.user) {
        this.logout()
      }

      if (this.$route.query.conf_no) {
        this.reservationCreatedFromBooking.confirmation_no = this.$route.query.conf_no;
        this.reservationCreatedFromBooking.show = true;
        setTimeout(() => {
          this.reservationCreatedFromBooking.show = false;
          this.$router.replace({});
        }, 4000);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$blue: #46a0fc;

.el-header {
  padding: 10px 20px !important;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #dadada;

  .confirmation-alert {
    position: absolute;
    width: 37%;
    left: 30%;
    top: 12px;
  }


  .title {
    display: flex;
    align-items: center;

    img {
      max-width: 35px;
      height: 35px;
      margin-right: 10px;
    }
  }

  .name {
    display: block;
    position: relative;
    cursor: pointer;
    min-width: 50px;
    user-select: none;

    .user-name {
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 5px;
      background-color: #fff;
      color: #000;

      .user-icon {
        margin-right: 5px;
      }

      span {
        text-transform: capitalize;
      }

      .caret-icon {
        margin-left: 5px;
      }
    }

    .logout {
      position: absolute;
      padding: 5px;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      top: 38px;
      left: 0;
      right: 0;
      background-color: #fff;
      z-index: 99;
      display: flex;
      align-items: center;

      span {
        margin-left: 5px;
      }
    }

    .logout:hover {
      background-color: $blue;
      color: white;
    }
  }
}
.user-icon-mobile{
  display: none;
}
@media (max-width: 768px) and (min-width: 420px) {
  .el-header {
    .confirmation-alert {
      position: absolute;
      width: 45% !important;
      left: 25% !important;
      top: 12px;
    }
  }
}

@media (max-width: 420px) {
  .el-header {
    .name {
      display: none;
    }
    .confirmation-alert {
      position: absolute;
      width: 90%;
      top: 70px;
      left: 5%;
    }

    .title {
      .img {
        height: 15px;
      }
    }
    .user-icon-mobile{
      display: block;
      color: black;
      font-size: 20px;
      z-index: 9;
    }
  }
}
</style>
