import store from '@/store/store'
import dayjs from "dayjs";
import router from "@/router"
import mutations from "@/store/mutation_types";
import LoginService from "@/views/login/login.service";

export const getCookie = (name) => {
  return localStorage.getItem(name);
};

export const checkPermissionValue = (permissions, permObject) => {
  let permissionStatus = 'NONE'
  if (permissions.length > 0) {
    permissions.forEach(element => {
      if (element.object.code === permObject) {
        permissionStatus = element.access_right
      }
    })
  }
  return permissionStatus
}

export const checkPermission = (permissions, permObject, accessRightStatus) => {
  let storeObject = permissions.find((item) => {
    return (item.object.code === permObject)
  })

  if (storeObject && storeObject.access_right === accessRightStatus) {
    return true
  }
  return false
}

export const clearLocalStorage = () => {
  const items = {...localStorage}
  localStorage.clear()
  Object.keys(items).forEach((key) => {
    if (key.includes('_FD') || key == "CodeVerifier") {
      localStorage.setItem(key, items[key])
    }
  })
}

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const hkTokenOrBearerToken = (token) => {
  const ssoInUse = store.state.current.sso_login || store.state.current.cognito_login
  if (ssoInUse) {
    return {
      'Authorization': token
    }
  } else {
    return {
      'hk-token': token
    }
  }
}
export const hkTokenOrBearerTokenCognito = (token) => {
  return {
    'Authorization': token
  }
}

function setCookiesForBackwardCompatibility(domain,
                                            user,
                                            property,
                                            token,
                                            sso_login,
                                            user_password_permission,
                                            property_id,
                                            property_name,
                                            property_code,
                                            property_logo,
                                            property_currency,
                                            property_type,
                                            region_url,
                                            user_role,
                                            userProperty,
                                            goToEvent,
                                            cognito_login) {
  if (am_I_atLocalhost()) {
    document.cookie = `hk_token=${encodeURIComponent(localStorage.getItem('hk_token'))}`
    document.cookie = `hk_token_expiry=${encodeURIComponent(localStorage.getItem('hk_token_expiry'))}`
    document.cookie = `hk_hashedkey=${store.state.current.hashed_password}`
    document.cookie = `hk_logged_user=${user.username}`
    document.cookie = `hk_logged_user_firstname=${user.first_name}`
    document.cookie = `hk_logged_user_lastname=${user.last_name}`
    document.cookie = `hk_logged_user_id=${user.id}`
    document.cookie = `hk_home_url=${window.location.href.split('#')[0]}`
    document.cookie = `hk_language=${property?.user_property_preferred_language || user.language_code}`
    document.cookie = `hk_allow_password_setup=${user_password_permission}`

    document.cookie = `hk_logged_property_id=${property_id}`
    document.cookie = `hk_logged_property_name=${property_name}`
    document.cookie = `hk_logged_property_code=${property_code}`
    document.cookie = `hk_property_logo=${property_logo}`
    document.cookie = `hk_currency=${property_currency}`
    document.cookie = `hk_region_url=${region_url || null}`
    document.cookie = `hk_user_template_id=${userProperty?.origin_user_template_id || null}`
    document.cookie = `hk_property_type=${property_type}`

    if (token.includes('GOOGLE')) {
      document.cookie = 'hk_signed_in_as=hotelkeyapp.com'
    } else if (sso_login) {
      document.cookie = 'hk_sso_in_use=true'
    } else {
      document.cookie = 'hk_signed_in_as=user'

    }
    if (user.role !== null) {
      document.cookie = `hk_role_code=${user_role}`
    }
    if (goToEvent) {
      document.cookie = `event_view=${goToEvent}`
    }

    if (cognito_login) {
      document.cookie = 'cognito_login=true'
    }
  } else {
    document.cookie = `hk_token=${encodeURIComponent(token)};path=/;domain=${domain}`
    document.cookie = `hk_hashedkey=${store.state.current.hashed_password};path=/;domain=${domain}`
    document.cookie = `hk_logged_user=${user.username};path=/;domain=${domain}`
    document.cookie = `hk_logged_user_firstname=${user.first_name};path=/;domain=${domain}`
    document.cookie = `hk_logged_user_lastname=${user.last_name};path=/;domain=${domain}`
    document.cookie = `hk_logged_user_id=${user.id};path=/;domain=${domain}`
    document.cookie = `hk_home_url=${window.location.href.split('#')[0]};path=/;domain=${domain}`
    document.cookie = `hk_language=${user.language_code};path=/;domain=${domain}`
    document.cookie = `hk_allow_password_setup=${user_password_permission};path=/;domain=${domain}`

    document.cookie = `hk_logged_property_id=${property_id};path=/;domain=${domain}`
    document.cookie = `hk_logged_property_name=${property_name};path=/;domain=${domain}`
    document.cookie = `hk_logged_property_code=${property_code};path=/;domain=${domain}`
    document.cookie = `hk_property_logo=${property_logo};path=/;domain=${domain}`
    document.cookie = `hk_region_url=${region_url || null};path=/;domain=${domain}`
    document.cookie = `hk_currency=${property_currency};path=/;domain=${domain}`
    document.cookie = `hk_user_template_id=${userProperty?.origin_user_template_id || null};path=/;domain=${domain}`
    document.cookie = `hk_property_type=${property_type};path=/;domain=${domain}`

    if (token.includes('GOOGLE')) {
      document.cookie = `hk_signed_in_as=hotelkeyapp.com;path=/;domain=${domain}`
    } else if (sso_login) {
      document.cookie = `hk_sso_in_use=true;path=/;domain=${domain}`
    } else {
      document.cookie = `hk_signed_in_as=hotelkey_user;path=/;domain=${domain}`
      if (user.role !== null) {
        document.cookie = `hk_role_code=${user_role};path=/;domain=${domain}`
      }
    }
    if (goToEvent) {
      document.cookie = `event_view=${goToEvent};path=/;domain=${domain}`
    }

    if (cognito_login) {
      document.cookie = 'cognito_login=true;path=/;domain=${domain}'
    }
  }
}

export const setCookies = (goToEvent) => {
  let user = store.state.current.user
  let user_password_permission = checkPermission(store.state.current.enterprise_permissions, 'CHANGE_PASSWORD', 'READ_WRITE')
  let user_properties = store.state.current.user_properties
  let property = store.state.current.property
  let domain = hkConfigEnvs.VUE_APP_ENT_DOMAIN_NAME
  let sso_login = store.state.current.sso_login
  let cognito_login = store.state.current.cognito_login
  let userProperty = user_properties?.find(item => item.id === property?.id)
  let property_type
  let user_role
  let token = localStorage.getItem('hk_token')
  let token_expiry = localStorage.getItem('hk_token_expiry')
  let region_url = store.state.current.dynamic_url
  let property_id = property ? property.id : null
  let property_name = property ? property.name : null
  let property_code = property ? property.code : null
  let property_logo = property ? property.business_logo : null
  let property_currency = property ? property.currency : null

  let logout_url = hkConfigEnvs.VUE_APP_LOGOUT_URL;
  localStorage.setItem("hk_logout_url", logout_url);

  if (property) {
    if (property.property_type === 'HOTEL') {
      property_type = 'PMS'
    } else {
      property_type = 'RETAILKEY'
    }
  } else {
    property_type = null
  }

  if (user.role !== null) {
    user_role = user.role.code
  }

  localStorage.setItem("hk_domain", domain)
  //localStorage.setItem("hk_token", token)
  //localStorage.setItem("hk_token_expiry", token_expiry)
  localStorage.setItem("hk_hashedkey", store.state.current.hashed_password)
  localStorage.setItem("hk_logged_user", user.username)
  localStorage.setItem("hk_logged_user_firstname", user.first_name)
  localStorage.setItem("hk_logged_user_lastname", user.last_name)
  localStorage.setItem("hk_logged_user_id", user.id)
  localStorage.setItem("hk_home_url", window.location.href.split('#')[0])
  localStorage.setItem("hk_language", property?.user_property_preferred_language || user.language_code)
  localStorage.setItem("hk_allow_password_setup", user_password_permission)
  
  if (property_id) {
    localStorage.setItem("hk_logged_property_id", property_id)
    localStorage.setItem("hk_logged_property_name", property_name)
    localStorage.setItem("hk_logged_property_code", property_code)
    localStorage.setItem("hk_property_logo", property_logo)
    localStorage.setItem("hk_region_url", region_url || null)
    localStorage.setItem("hk_currency", property_currency)
    localStorage.setItem("hk_user_template_id", userProperty?.origin_user_template_id || null)
    localStorage.setItem("hk_property_type", property_type)
  }


  /**
   * Cookies Section, they are depracted now and will be removed in the near future
   * //TODO: Remove cookie statement after localStorage upgrade
   */
  setCookiesForBackwardCompatibility(domain,
    user,
    property,
    token,
    sso_login,
    user_password_permission,
    property_id,
    property_name,
    property_code,
    property_logo,
    property_currency,
    property_type,
    region_url,
    user_role,
    userProperty,
    goToEvent,
    cognito_login);


  if (localStorage.getItem('hk_token')?.includes('GOOGLE')) {
    localStorage.setItem("hk_signed_in_as", "hotelkeyapp.com")
  } else if (sso_login) {
    localStorage.setItem("hk_sso_in_use", "true")
  } else {
    localStorage.setItem("hk_signed_in_as", "hotelkey_user")
    if (user.role !== null) {
      localStorage.setItem("hk_role_code", user_role)
    }
  }

  if (goToEvent) {
    localStorage.setItem("event_view", goToEvent)
  }

  if (cognito_login) {
    localStorage.setItem("cognito_login", "true")
  }
}


export const am_I_atLocalhost = () => {
  let host = window.location.host
  return host.indexOf(':') !== -1 && host.split(':')[0] === 'localhost'
}

export const logout = () => {

  try {
    store.commit(mutations.SET_CURRENT_TO_NULL)
    //window.removeEventListener('storage', localStorageEventListener);
  } catch (error) {
    console.error(error)
  }

  router.push("/logout").catch((e) => {console.info("Logging out...")});

  /*clearLocalStorage()
  clearAllCookies()

  let logout_url = hkConfigEnvs.VUE_APP_LOGOUT_URL;

  if (logout_url && !am_I_atLocalhost()) {
    window.location.href = logout_url;
  } else {
    window.location.href = `${window.location.protocol}//${window.location.hostname}:8070/#/login`
  }*/
}

export const hasTokenExpired = (token_expiry_date) => {
  if (am_I_atLocalhost()) return false;

  const expiry_date = dayjs(token_expiry_date)
  const now = dayjs(new Date().getTime())
  let diff = expiry_date.diff(now, 'minute')

  if (isNaN(diff) || diff == null || diff == undefined) {
    return true;
  } else if (diff < 0) {
    return true;
  } else {
    return false;
  }
}

export const localStorageEventListener = (event) => {
  if (event.storageArea === localStorage) {
    // Handle localStorage change here
    // console.log('localStorage has changed', event, Date.now());
    setTimeout(() => {

      try {

        if (!getCookie('hk_token')) logout();

        let hk_token_expiry = getCookie('hk_token_expiry')
        if (hk_token_expiry && hk_token_expiry !== "null") {
          if (hasTokenExpired(hk_token_expiry)) {
            LoginService.refreshToken().then((resp) => {
              console.log("token refreshed: ", resp.data);

              store.commit(mutations.SET_HK_TOKEN, `Bearer ${resp.data.token}`)
              store.commit(mutations.SET_TOKEN_EXPIRY_DATE, resp.data.token_expiry)

              setCookies(false);
            }).catch(error => {
              if (error.response?.status === 403) {
                logout();
              }
            })
          }
        }


        if (getCookie('hk_token')) {
          if (router?.currentRoute?.name?.indexOf('login') !== -1) {
            window.location.reload();
          }
        }

      } catch (error) {
        console.error(error);
      }

    },1000)


  }
}

export const clearAllCookies = () => {
  let cookies = document.cookie.split("; ");

  for (let c = 0; c < cookies.length; c++) {
    let d = window.location.hostname.split(".");
    while (d.length > 0) {
      let cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      let p = location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }

      d.shift();
    }
  }
}
