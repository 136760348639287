export default {
  SET_HK_TOKEN: 'SET_HK_TOKEN',
  SET_HK_INFO: 'SET_HK_INFO',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_CURRENT_PROPERTY: 'SET_CURRENT_PROPERTY',
  SET_CURRENT_USER_HASHED_PASSWORD: 'SET_CURRENT_USER_HASHED_PASSWORD',
  SET_CURRENT_USER_PROPERTIES: 'SET_CURRENT_USER_PROPERTIES',
  SET_CURRENT_TO_NULL: 'SET_CURRENT_TO_NULL',
  SET_CURRENT_BUSINESS_DAY: 'SET_CURRENT_BUSINESS_DAY',
  SET_DASHBOARD_DATE: 'SET_DASHBOARD_DATE',
  SET_REFRESH_CLICK_TIMESTAMP: 'SET_REFRESH_CLICK_TIMESTAMP',
  INITIALIZE_STORE: 'INITIALIZE_STORE',
  SET_GOOGLE_LOGIN: 'SET_GOOGLE_LOGIN',
  SET_TOKEN_EXPIRY_DATE: 'SET_TOKEN_EXPIRY_DATE',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_DYNAMIC_URL: 'SET_DYNAMIC_URL',
  SET_ENTERPRISE_PERMISSIONS: 'SET_ENTERPRISE_PERMISSIONS',
  SET_PROPERTIES_FROM_USER: 'SET_PROPERTIES_FROM_USER',
  SET_SSO_LOGIN: 'SET_SSO_LOGIN',
  SET_SSO_LOGIN_DETAILS: 'SET_SSO_LOGIN_DETAILS',
  SET_COGNITO_LOGIN: 'SET_COGNITO_LOGIN',
  SET_TRIGGER_INTERVAL_ID: 'SET_TRIGGER_INTERVAL_ID',
  RESET_CURRENT_PROPERTY: 'RESET_CURRENT_PROPERTY'
}
