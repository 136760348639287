import axios from 'axios'

// import {getCookie} from '@/common/utils'

// const region_url = window.location.origin.includes('localhost') ? `${hkConfigEnvs.VUE_APP_BASE_URL_API}` : getCookie('hk_region_url').slice(0, -1);
// const api_base_url = `${region_url}/${hkConfigEnvs.VUE_APP_CORE_API}/${hkConfigEnvs.VUE_APP_ENTERPRISE_IDENTIFIER}`;
const api_url = `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}`;

export default {

    getCognito(params) {
      const url = `${api_url}/account/signin/init/cognito`

      return axios.get(url, {params, cache: true })
    },
}
