export default {
  enterprise_apps: [
    {
      module_name: "Booking",
      icon: `${require('@/assets/home/booking_blue.svg')}`,
      children: [
        {
          name: "Find and Reserve",
          url: `${hkConfigEnvs.VUE_APP_PRIVATE_BOOKING_URL}`,
          permission_object: "ENABLE_ENT_BOOKING"
        }
      ]
    },{
      module_name: "Development",
      icon: `${require('@/assets/home/booking_blue.svg')}`,
      children: [
        {
          name: "Storybook",
          url: `${hkConfigEnvs.VUE_APP_STORYBOOK}`,
          permission_object: "ENABLE_ENT_SAFETYKEY"
        }
      ]
    },
    {
      module_name: "Booking Management",
      icon: `${require('@/assets/home/booking_blue.svg')}`,
      children: [
        {
          name: "Search & Modify",
          url: `${hkConfigEnvs.VUE_APP_SEARCH_AND_MODIFY_URL}`,
          permission_object: "ENABLE_ENT_SEARCH_MODIFY"
        }
      ]
    },
    {
      module_name: "Loyalty Management",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "Loyalty Management",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_LOYALTY_MANAGEMENT}`,
          permission_object: "ENABLE_ENT_LOYALTY_MANAGEMENT"
        },
        {
          name: "RetailKey Loyalty Management",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_RETAILKEY_LOYALTY_MANAGEMENT}`,
          permission_object: "ENTERPRISE_RETAILKEY_LOYALTY"
        }
      ]
    },

    {
      module_name: "Reports",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "Enterprise Reports",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_REPORTS_URL}`,
          permission_object: "ENTERPRISE_DYNAMIC_REPORTS"
        }
      ]
    },
    {
      module_name: "TrainKey",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "TrainKey",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_TRAINKEY_URL}`,
          permission_object: "ENABLE_ENT_TRAINKEY"
        }
      ]
    },
    {
      module_name: "Administration",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "HK Admin",
          url: `${hkConfigEnvs.VUE_APP_HK_ADMIN_URL}`,
          permission_object: "ENABLE_ENT_ADMIN_LEGACY",
          open_in_new_tab: true,
          callback: ()=>{
            localStorage.removeItem('hk-simple-redux-store')
            sessionStorage.removeItem('hk-simple-redux-store')
          }
        },
        {
          name: "User Management",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_USER_MANAGEMENT_URL}`,
          permission_object: "ENABLE_ENT_USER_MANAGEMENT"
        },
        {
          name: "PaymentKey",
          url: `${hkConfigEnvs.VUE_APP_PAYMENTKEY_URL}`,
          permission_object: "ENABLE_ENT_PAYMENTKEY"
        },
        {
          name: "Enterprise Configuration",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_CONFIGURATION_URL}`,
          permission_object: "ENABLE_ENTERPRISE_CONFIGURATION_APP"
        }
      ]
    },
    {
      module_name: "CRS",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "Central Reservation System",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_CENTRAL_RESERVATION_SYSTEM_URL}`,
          permission_object: "ENABLE_ENT_CRS"
        },
      ]
    },
  ],
  property_apps: [
    {
      module_name: "Operations",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "Frontdesk",
          url: `${hkConfigEnvs.VUE_APP_FRONTDESK_URL}`,
          permission_object: "ENABLE_FRONTDESK",
          params: "origin",
          property_type: "ALL"
        },{
          name: "FrontDesk NG",
          url: `${hkConfigEnvs.VUE_APP_FRONTDESK_NG_URL}`,
          permission_object: "ENABLE_FRONTDESK_NG",
          params: "origin",
          property_type: "ALL"
        },
        {
          name: "Companies and Direct Bill",
          url: `${hkConfigEnvs.VUE_APP_COMPANIES_URL}`,
          permission_object: "ENABLE_LOCAL_COMPANIES",
          property_type: "HOTEL"
        },
        {
          name: "Reports",
          url: `${hkConfigEnvs.VUE_APP_REPORTS_URL_V2}`,
          permission_object: "ENABLE_PROPERTY_REPORTS",
          property_type: "ALL"
        },
        {
          name: "Timekeeping",
          url: `${hkConfigEnvs.VUE_APP_TIMEKEEPING_URL}`,
          permission_object: "ENABLE_TIMEKEEPING",
          property_type: "HOTEL"
        },
        {
          name: "Signature Templates",
          url: `${hkConfigEnvs.VUE_APP_SIGNATURE_TEMPLATE_URL}`,
          permission_object: "ENABLE_SIGNATURE_TEMPLATE",
          cookie_param: "signature_templates",
          property_type: "ALL"
        },
        {
          name: "Tasks, Checklists and Engineering",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_HOUSEKEEPING_AND_MAINTENANCE_URL}`,
          permission_object: "ENABLE_HOUSEKEEPING_MAINTENANCE",
          property_type: "ALL"
        }
      ]
    },
    {
      module_name: "Analytics and Dashboard",
      icon: `${require('@/assets/home/dashboard_blue.svg')}`,
      children: [
        {
          name: "Property Dashboard",
          url: `${hkConfigEnvs.VUE_APP_PROPERTY_DASHBOARDS_URL}`,
          permission_object: "ENABLE_PROPERTY_DASHBOARD",
          property_type: "HOTEL"
        }
      ]
    },
    {
      module_name: "Revenue Management",
      icon: `${require('@/assets/home/Revenue-management_blue.svg')}`,
      children: [
        {
          name: "Rate Calendar",
          url: `${hkConfigEnvs.VUE_APP_REVENUE_MANAGEMENT_URL}`,
          permission_object: "ENABLE_RATE_CALENDAR",
          property_type: "HOTEL"
        },
        {
          name: "Rate Administration",
          url: `${hkConfigEnvs.VUE_APP_ROOM_TYPE_RATE_CODE_MANAGEMENT_URL}`,
          permission_object: "ENABLE_RATE_CODE_ROOM_TYPE",
          property_type: "HOTEL"
        }
      ]
    },
    {
      module_name: "Booking",
      icon: `${require('@/assets/home/booking_blue.svg')}`,
      children: [
        {
          name: "Search Reservation",
          url: `${hkConfigEnvs.VUE_APP_BOOKING_URL}`,
          permission_object: "ENABLE_INDIVIDUAL_BOOKING",
          route_path: "landing-page",
          params: "pc,property_id,origin",
          property_type: "HOTEL"
        },
        {
          name: "Book a Room",
          url: `${hkConfigEnvs.VUE_APP_BOOKING_URL}`,
          permission_object: "ENABLE_INDIVIDUAL_BOOKING",
          route_path: "",
          params: "pc,property_id,origin",
          property_type: "HOTEL"
        },
        {
          name: "Book a Group",
          url: `${hkConfigEnvs.VUE_APP_BOOKING_URL}`,
          permission_object: "ENABLE_GROUP_BOOKING",
          route_path: "group-stay",
          params: "pc,property_id,origin",
          property_type: "HOTEL"
        }
      ]
    },
    {
      module_name: "RetailKey",
      icon: `${require('@/assets/home/inventory.svg')}`,
      children: [
        {
          name: "Administration",
          url: `${hkConfigEnvs.VUE_APP_POS_MENUS_URL}`,
          permission_object: "ENABLE_POS_MENUS",
          property_type: "ALL"
        },
        {
          name: "POS Register",
          url: `${hkConfigEnvs.VUE_APP_POS_REGISTER_URL}`,
          permission_object: "ENABLE_POS_REGISTER",
          property_type: "ALL"
        },
        {
          name: "Administration v2",
          url: `${hkConfigEnvs.VUE_APP_RETAILKEY_ADMIN_URL}`,
          permission_object: "ENABLE_REATAILKEY_ADMIN_V2",
          property_type: "ALL"
        },
        {
          name: "KOT",
          url: `${hkConfigEnvs.VUE_APP_KOT_URL}`,
          permission_object: "ENABLE_REATAILKEY_KDS",
          property_type: "ALL"
        },
      ]
    },
    {
      module_name: "EventKey",
      icon: `${require('@/assets/home/event-management.svg')}`,
      children: [
        {
          name: "Administration",
          url: `${hkConfigEnvs.VUE_APP_EVENT_MANAGEMENT_URL}`,
          permission_object: "ENABLE_EVENT_MANAGEMENT_ADMIN",
          cookie_param: "administration",
          property_type: "ALL"
        },
        {
          name: "Manage Events",
          url: `${hkConfigEnvs.VUE_APP_EVENT_MANAGEMENT_URL}`,
          permission_object: "ENABLE_EVENT_MANAGEMENT",
          cookie_param: "manage_events",
          property_type: "ALL"
        }
      ]
    },
    {
      module_name: "Administration",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "Property Settings",
          url: `${hkConfigEnvs.VUE_APP_PROPERTY_MANAGEMENT_URL}`,
          permission_object: "ENABLE_PROPERTY_MANAGEMENT",
          property_type: "ALL"
        },
        {
          name: "User Management",
          url: `${hkConfigEnvs.VUE_APP_USER_MANAGEMENT_URL}`,
          permission_object: "ENABLE_USER_MANAGEMENT",
          property_type: "ALL"
        },
        {
          name: "Advanced Batch Actions",
          url: `${hkConfigEnvs.VUE_APP_ADVANCED_BATCH_ACTIONS}`,
          permission_object: "RESERVATIONS_ADVANCE_SEARCH",
          property_type: "HOTEL"
        }
      ]
    },
    {
      module_name: "ActivityKey",
      icon: `${require('@/assets/home/event-management.svg')}`,
      children: [
        {
          name: "Administration",
          url: `${hkConfigEnvs.VUE_APP_ACTIVITIES_ADMIN_URL}`,
          permission_object: "ENABLE_ACTIVITIES_ADMIN_APP",
          cookie_param: "activities",
          property_type: "ALL"
        },
        {
          name: "Manage Activities",
          url: `${hkConfigEnvs.VUE_APP_ACTIVITIES_URL}`,
          permission_object: "ENABLE_ACTIVITIES_APP",
          cookie_param: "activities",
          property_type: "ALL"
        },

      ]
    },
    {
      module_name: "TenantKey",
      icon: `${require('@/assets/home/booking_blue.svg')}`,
      children: [
        {
          name: "TenantKey Admin",
          url: `${hkConfigEnvs.VUE_APP_TENANTKEY}`,
          permission_object: "ENABLE_TENANT_KEY",
          property_type: "ALL"
        },
      ]
    },

  ]
}
